<template>
	<div class="page">
		<el-form :inline="true" style="background-color: #fff;padding-top: 20px;">
			<el-form-item label="日期查询">
				<el-date-picker class="from-item-width" size="small" unlink-panels v-model="searchFormData.dateRang" type="daterange" value-format="yyyy-MM-dd" placeholder="按评论时间">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="城市" prop="citys">
				<el-select v-model="searchFormData.city" placeholder="请选择" size="small" clearable @change="changeCity">
					<el-option label="全部" value="10"></el-option>
					<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<!--搜索-->
			<el-form-item>
				<el-button type="primary" @click="getList1" size="small" icon="el-icon-search" plain>查询
				</el-button>
			</el-form-item>
			<!--重置-->
			<el-form-item>
				<el-button @click="resetForm" size="small" icon="el-icon-refresh" plain>重置
				</el-button>
			</el-form-item>
		</el-form>
		<div style="background-color: #fff;padding: 10px;margin-top: 10px;margin-bottom: 10px;">
			<div style="font-weight: bold;">营收统计</div>
			<div class="boxs">
				<div class="box">
					<div class="title">充电量</div>
					<div class="vals"><a>{{ (Number(sums.electric) + Number(sums.electric_other)).toFixed(2) }}</a>度</div>
					<hr/>
					<v-chart :option="eleOption1" autoresize style="height:80px"></v-chart>
				</div>
				<div class="box">
					<div class="title">充电金额</div>
					<div class="vals"><a>{{ (Number(sums.pay_ele) + Number(sums.pay_service)).toFixed(2) }}</a>元</div>
					<hr/>
					<v-chart :option="eleOption2" autoresize style="height:80px"></v-chart>
				</div>
				<div class="box">
					<div class="title">电卡销售</div>
					<div class="vals"><a>{{ (Number(sums.szCard) + Number(sums.wxCard)).toFixed(2) }}</a>元</div>
					<hr/>
					<v-chart v-if="!searchFormData.city || searchFormData.city == 10" :option="eleOption3" autoresize style="height:80px"></v-chart>
				</div>
				<div class="box">
					<div class="title">会员销售</div>
					<div class="vals"><a>{{ (Number(sums.szVip) + Number(sums.wxVip)).toFixed(2) }}</a>元</div>
					<hr/>
					<v-chart v-if="!searchFormData.city || searchFormData.city == 10" :option="eleOption4" autoresize style="height:80px"></v-chart>
				</div>
			</div>
		</div>

		<div style="display: flex;justify-content: space-between;background-color: #fff;padding: 10px;">营收报表 <el-button @click="exportData('tb_grouplist2')" size="small" icon="el-icon-document" plain>导出</el-button></div>
		<el-table :data="List1" id="tb_grouplist2">
			<el-table-column prop="city" label="城市" align="center" />
			<el-table-column label="充电量(度)" align="center">
				<template slot-scope="scope">
					总电量: {{ (Number(scope.row.electric) + Number(scope.row.electric_other)).toFixed(2) }}<br/>
					叮 当: {{ scope.row.electric }}<br/>
					第三方: {{ scope.row.electric_other }}<br/>
				</template>
			</el-table-column>
			<el-table-column label="电费" align="center">
				<template slot-scope="scope">
					总电费: {{ scope.row.pay_ele }}<br/>
					叮当: {{ (Number(scope.row.pay_ele)-Number(scope.row.pay_ele_xing)-Number(scope.row.pay_ele_hlht)).toFixed(2) }}<br/>
					星星: {{ scope.row.pay_ele_xing }}<br/>
					云快充: {{ scope.row.pay_ele_hlht }}<br/>
				</template>
			</el-table-column>
			<el-table-column label="服务费" align="center">
				<template slot-scope="scope">
					总服务费: {{ scope.row.pay_service }}<br/>
					叮当: {{ (Number(scope.row.pay_service)-Number(scope.row.pay_service_xing)-Number(scope.row.pay_service_hlht)).toFixed(2) }}<br/>
					星星: {{ scope.row.pay_service_xing }}<br/>
					云快充: {{ scope.row.pay_service_hlht }}<br/>
				</template>
			</el-table-column>
			<el-table-column prop="pay_money" label="充电金额" align="center" />
			<el-table-column prop="rate" label="毛利率" align="center" />
			<el-table-column prop="avg_price" label="平均电价" align="center" />
			<el-table-column prop="sell_card" label="电卡销售" align="center" />
			<el-table-column prop="sell_vip" label="会员销售" align="center" />
		</el-table>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Tables from '@/components/tables'
	import XLSX from "xlsx"
	import VueECharts from "vue-echarts"

	export default {
		name: 'data-analysis-report',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '营收统计',
				isLoading: false,
				searchFormData: {city:'10'},
				List1: [],
				//汇总数据
				sums: {},
				totalPage1: 0,
				//场站列表
				groupList:[],
				chartOption:{
					legend: {
						orient: 'vertical',
						top: 'middle',
						data: ['直接访问', '联盟广告']
					},					
					series: [{
						type: 'pie',
						label: {
							show: false,
							position: 'center'
						},
						width:50,
						data: [
							{value: 335,name: '直接访问'},
							{value: 234,name: '联盟广告'}
						]
					}]
				},
				eleOption1:{},
				eleOption2:{},
				eleOption3:{},
				eleOption4:{},
			}
		},
		computed: {
		},
		mounted() {
			this.clearSearch();
			this.getList1();
		},
		activated() {
			
		},
		methods: {
			// 表格列表
			async getList1() {
				let params = {
					token: this.$store.state.user.token,
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.report2(params);
				this.sums = res.sums;
				let jstr = JSON.stringify(this.chartOption);
				this.eleOption1 = JSON.parse(jstr);
				this.eleOption1.legend.data = ["叮当 "+this.sums.electric, "第三方 "+this.sums.electric_other];
				this.eleOption1.series[0].data = [
					{value: this.sums.electric, name: "叮当 "+this.sums.electric},
					{value: this.sums.electric_other, name: "第三方 "+this.sums.electric_other}
				];
				this.eleOption2 = JSON.parse(jstr);
				this.eleOption2.legend.data = ["电费 "+this.sums.pay_ele, "服务费 "+this.sums.pay_service];
				this.eleOption2.series[0].data = [
					{value: this.sums.pay_ele, name: "电费 "+this.sums.pay_ele},
					{value: this.sums.pay_service, name: "服务费 "+this.sums.pay_service}
				];
				this.eleOption3 = JSON.parse(jstr);
				this.eleOption3.legend.data = ["苏州 "+this.sums.szCard, "无锡 "+this.sums.wxCard];
				this.eleOption3.series[0].data = [
					{value: this.sums.szCard, name: "苏州 "+this.sums.szCard},
					{value: this.sums.wxCard, name: "无锡 "+this.sums.wxCard}
				];
				this.eleOption4 = JSON.parse(jstr);
				this.eleOption4.legend.data = ["苏州 "+this.sums.szVip, "无锡 "+this.sums.wxVip];
				this.eleOption4.series[0].data = [
					{value: this.sums.szVip, name: "苏州 "+this.sums.szVip},
					{value: this.sums.wxVip, name: "无锡 "+this.sums.wxVip}
				];
				this.List1 = res.list;
				for (let v of this.List1) {
					v.rate = (v.pay_service*100/(Number(v.electric)+Number(v.electric_other))).toFixed(2)+"%";
					v.avg_price = (v.pay_money/(Number(v.electric)+Number(v.electric_other))).toFixed(2);
					if(v.city == '苏州市'){
						v.sell_card = this.sums.szCard||0;
						v.sell_vip = this.sums.szVip||0;
					}else if(v.city == '无锡市'){
						v.sell_card = this.sums.wxCard||0;
						v.sell_vip = this.sums.wxVip||0;
					}
				}
				this.isLoading = false;
			},
			// 重置搜索
			clearSearch() {
				if(!this.searchFormData.dateRang){
					let dt1 = new Date(), dt2 = new Date();
					dt1.setDate(dt1.getDate()-7);
					dt2.setDate(dt2.getDate()-1);
					let dstr1 = dt1.getFullYear() + "-" + (dt1.getMonth()<9?'0':'') + (dt1.getMonth()+1) + "-" + (dt1.getDate()<10?'0':'') + dt1.getDate();
					let dstr2 = dt2.getFullYear() + "-" + (dt2.getMonth()<9?'0':'') + (dt2.getMonth()+1) + "-" + (dt2.getDate()<10?'0':'') + dt2.getDate();
					//this.searchFormData.dateRang = [dstr1, dstr2];
					this.$set(this.searchFormData, "dateRang", [dstr1, dstr2]);	
				}
			},
			resetForm(){
				this.clearSearch();
			},
			changeCity(e){ //修改城市
				if(e.indexOf('10') != -1) this.searchFormData.group_ids = [];
			},
			exportData(tbid){ //导出数据
				//var table_elt = document.getElementById(tbid);
				// Extract Data (create a workbook object from the table)
				//var workbook = XLSX.utils.table_to_book(table_elt);
				let workbook = XLSX.utils.book_new();
				let vals = [{
					"city":"城市",
					"electric":"充电总量",
					"pay_ele_dd":"叮当电费",
					"pay_ele_xing":"星星电费",
					"pay_ele_hlht":"云快充电费",
					"pay_service_dd":"叮当服务费",
					"pay_service_xing":"星星服务费",
					"pay_service_hlht":"云快充服务费",
					"pay_money":"充电金额",
					"rate":"毛利率",
					"avg_price":"平均单价",
					"sell_card":"电卡销售",
					"sell_vip":"会员销售"
				}];
				for(let v of this.List1){
					vals.push({
						city:v.city,
						electric:(Number(v.electric)+Number(v.electric_other)).toFixed(2),
						pay_ele_dd:(Number(v.pay_ele)-Number(v.pay_ele_xing)-Number(v.pay_ele_hlht)).toFixed(2),
						pay_ele_xing:v.pay_ele_xing,
						pay_ele_hlht:v.pay_ele_hlht,
						pay_service_dd:(Number(v.pay_service)-Number(v.pay_service_xing)-Number(v.pay_service_hlht)).toFixed(2),
						pay_service_xing:v.pay_service_xing,
						pay_service_hlht:v.pay_service_hlht,
						pay_money:v.pay_money,
						rate:(v.pay_service*100/(Number(v.electric)+Number(v.electric_other))).toFixed(2)+"%",
						avg_price:(v.pay_money/(Number(v.electric)+Number(v.electric_other))).toFixed(2),
						sell_card:v.sell_card,
						sell_vip:v.sell_vip
					});
				}
				let worksheet = XLSX.utils.json_to_sheet(vals, {header:["city"], skipHeader: true});
				XLSX.utils.book_append_sheet(workbook, worksheet);
				// Package and Release Data (`writeFile` tries to write and save an XLSB file)
				XLSX.writeFile(workbook, "out.xlsx");
			}
		}
	}
</script>

<style lang="scss" scoped>
.boxs{
	display: flex;
	justify-content: space-around;
	.box{
		background-color: #f6f7f9;
		padding: 8px;
		min-width: 220px;
		.title{
			font-size: 14px;
		}
		.vals{
			font-size: 12px;
			color: #999;
			a{
				font-size: 28px;
				font-weight: bold;
				color: #000;
			}
		}		
	}

}
</style>
